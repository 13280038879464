import * as React from 'react';
import { graphql } from 'gatsby';
import { MainLayout } from '../components/MainLayout';
import NotFound from '../components/NotFound/NotFound';

const NotFoundPage = () => {
  return (
    <MainLayout>
      <NotFound />
    </MainLayout>
  );
};

export default NotFoundPage;

export const Head = () => <title>Not found</title>;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
