import React from 'react';
import { useTranslation } from 'react-i18next';
import routes from '../../static/constants/routes';
import PrimaryButtonWithLink from '../Common/Buttons/PrimaryButtonWithLink';

import SectionWrapper from '../Common/SectionWrapper';
import * as S from './NotFound.styles';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <SectionWrapper>
      <S.Wrapper>
        <S.Header>404</S.Header>
        <S.Description>{t('404_description')}</S.Description>
        <PrimaryButtonWithLink text={t('404_btn')} routeTo={routes.home} />
      </S.Wrapper>
    </SectionWrapper>
  );
};

export default NotFound;
