import styled from 'styled-components';
import { query } from '../../styles/mediaQuery';

export const Wrapper = styled.div`
  padding: 12rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  position: relative;

  @media ${query.desktop} {
    padding: 3rem 0;
  }
`;

export const Header = styled.h1`
  color: ${({ theme }) => theme.color.primaryOrange};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: 12rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin: 0;
  z-index: 2;

  @media ${query.desktop} {
    font-size: ${({ theme }) => theme.font.size.pageTitle};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
  }
`;

export const Description = styled.h3`
  color: ${({ theme }) => theme.color.textSecondary};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: ${({ theme }) => theme.font.size.headingMedium};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin: 0 0 2rem;
  z-index: 2;
  text-align: center;
  line-height: 3.6rem;

  @media ${query.desktop} {
    max-width: 600px;
    font-size: ${({ theme }) => theme.font.size.headingSmall};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
  }
`;
